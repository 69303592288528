import Vue from 'vue'
import Router from 'vue-router'
import message from '@/utils/tips.js'

import {
  getToken
} from '@/utils/auth'
Vue.use(Router)

import Layout from '@/components/layout'

export const constantRoutes = [{
  path: '*',
  redirect: '/'
},
{
  path: '/',
  component: Layout,
  redirect: '/',
  children: [{
    path: '/',
    name: '首页',
    component: () => import('@/views/home/index'),
    meta: {}
  }, {
    path: '/organization',
    name: '组织机构',
    component: () => import('@/views/organization/index'),
    meta: {}
  },
  {
    path: '/reporter',
    name: '大会报告人',
    component: () => import('@/views/reporter/index'),
    meta: {}
  },
  {
    path: '/schedule',
    name: '会议日程',
    component: () => import('@/views/schedule/index'),
    meta: {}
  },
  {
    path: '/attend',
    name: '注册参会',
    component: () => import('@/views/attend/index'),
    meta: {}
  },
  {
    path: '/stay',
    name: '住宿交通',
    component: () => import('@/views/stay/index'),
    meta: {}
  },
  {
    path: '/exhibition',
    name: '商务合作',
    component: () => import('@/views/exhibition/index'),
    meta: {}
  },
  // {
  //   path: '/scenic',
  //   name: '城市景点',
  //   component: () => import('@/views/scenic/index'),
  //   meta: {}
  // },
  {
    path: '/history',
    name: '历届会议',
    component: () => import('@/views/history/index'),
    meta: {}
  },
  {
    path: '/download',
    name: '下载中心',
    component: () => import('@/views/download/index'),
    meta: {}
  }, {
    path: '/login',
    name: '登录',
    component: () => import('@/views/login/login'),
    meta: {}
  }, {
    path: '/register',
    name: '注册',
    component: () => import('@/views/login/register'),
    meta: {}
  }, {
    path: '/forget',
    name: '忘记密码',
    component: () => import('@/views/login/forget'),
    meta: {}
  }, {
    path: '/newPasswd',
    name: '新密码',
    component: () => import('@/views/login/newPasswd'),
    meta: {}
  }, {
    path: '/user',
    name: '用户中心',
    component: () => import('@/views/user/index'),
    meta: {}
  }, {
    path: '/basic',
    name: '基本资料',
    component: () => import('@/views/basic/index'),
    meta: {}
  }, {
    path: '/changePassword',
    name: '修改密码',
    component: () => import('@/views/changePassword/index'),
    meta: {}
  }, {
    path: '/hotel',
    name: '酒店预订',
    component: () => import('@/views/hotel/index'),
    meta: {}
  }, {
    path: '/cost',
    name: '费用支付',
    component: () => import('@/views/cost/index'),
    meta: {}
  }, {
    path: '/invoice',
    name: '发票',
    component: () => import('@/views/invoice/index'),
    meta: {}
  }, {
    path: '/abstract',
    name: '墙报摘要',
    component: () => import('@/views/abstract/index'),
    meta: {}
  }, {
    path: '/success',
    name: '注册成功',
    component: () => import('@/views/login/success'),
    meta: {}
  }, {
    path: '/verify',
    name: '验证邮箱',
    component: () => import('@/views/login/verify'),
    meta: {}
  }, {
    path: '/paySuccess',
    name: '支付成功',
    component: () => import('@/views/pay/success'),
    meta: {}
  }, {
    path: '/payRecord',
    name: '支付纪录',
    component: () => import('@/views/pay/record'),
    meta: {}
  }, {
    path: '/payOrder',
    name: '订单页面',
    component: () => import('@/views/pay/order'),
    meta: {}
  }, {
    path: '/hotelReserve',
    name: '酒店预订',
    component: () => import('@/views/hotel/index'),
    meta: {}
  }, {
    path: '/hotelOrder',
    name: '酒店订单',
    component: () => import('@/views/hotelOrder/index'),
    meta: {}
  }, {
    path: '/hotelDetails',
    name: '订单详情',
    component: () => import('@/views/hotelOrder/details'),
    meta: {}
  }

  ]
},



]
const permissionList = ['/user', '/basic', '/cost', '/abstract', '/payOrder', '/payRecord', '/changePassword', '/hotelOrder', '/hotelDetails']


const createRouter = () => new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition
    }
    // return {
    //   x: 0,
    //   y: 0
    // }
  },

  routes: constantRoutes
})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

const router = createRouter()
router.afterEach(() => {
  window,
    scrollTo(0, 0)

})
// 路由跳转前执行
router.beforeEach((to, from, next) => {
  if (getToken()) {

    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/'
      })

    } else {
      next()
    }
  } else {
    // 没有token
    if (permissionList.indexOf(to.path) == -1) {

      next()
    } else {
      message.tips('您还未登陆,请登陆')
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页

    }
  }
})
export default router