import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
Vue.config.productionTip = false
import router from './router'
import store from './store'
import request from "@/utils/request";
import "@/assets/css/common.less";
import '@/assets/js/rem.js'
import md5 from 'js-md5';
import message from '@/utils/tips.js'
Vue.prototype.$request = request
Vue.prototype.$message = message
Vue.prototype.$md5 = md5;
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')