<template>
  <div>
    <div v-if="$store.state.navigation" class="mobile-item">
      <v-app-bar-nav-icon
        v-show="$store.state.navigation"
        @click.stop="drawer = true"
      >
        <v-icon>menu</v-icon>
      </v-app-bar-nav-icon>
      <div class="btns">
        <div v-if="$store.state.user.name">
          <span>欢迎 {{ $store.state.user.name }}</span>
          <span class="user ml-2" @click="jump('/user')">个人中心</span>
        </div>
        <div v-else>
          <span class="login" @click="jump('/login')">你好，请登录</span>
          <span class="register" @click="jump('/register')">注册</span>
        </div>
      </div>
      <v-navigation-drawer class="drawer" v-model="drawer" absolute temporary>
        <ul class="mobile-header">
          <li
            v-for="(item, inx) in navList"
            :key="inx"
            :class="inx == selectedItem ? 'active' : ''"
            @click="navClick(inx, item.path)"
          >
            <div class="nav">
              <span>{{ item.name }}</span>
              <i class="underline"></i>
            </div>
          </li>
        </ul>
      </v-navigation-drawer>
    </div>
    <div v-else class="pc-item">
      <div class="header-top">
        <div class="left">
          <!--          <i><img src="../../../assets/img/icon/7.png" /> </i>-->
          <!--          4000089521-->
          <i> <img src="../../../assets/img/icon/8.png" /> </i
          >molecularbreeding@163.com
          <i><img src="../../../assets/img/icon/9.png"/></i>7x24小时为您服务
        </div>
        <div class="right">
          <div v-if="$store.state.user.name">
            <span>欢迎 {{ $store.state.user.name }}</span>
            <span class="user ml-2" @click="jump('/user')">个人中心</span>
          </div>
          <div v-else>
            <span class="login" @click="jump('/login')" style="color: #f7f7f7"
              >您好，请登录</span
            >
            <span class="register" @click="jump('/register')">注册</span>
          </div>
          <!-- <span>xzasfdsf@qq.com</span> -->
        </div>
      </div>
      <div class="content">
        <div class="logo" style="width: 200px">
          <img style="width: 200px" src="../../../assets/img/logo.png" />
        </div>
        <ul class="header">
          <li
            v-for="(item, inx) in navList"
            :key="inx"
            :class="inx == selectedItem ? 'active' : ''"
            @click="navClick(inx, item.path)"
          >
            <div class="nav">
              <span>{{ item.name }}</span>
              <i class="underline"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      :class="$store.state.navigation ? 'banner2' : 'banner1'"
      @click="onJump"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      navList: [
        {
          name: '首页',
          path: '/',
          children: [],
        },
        {
          name: '组织机构',
          path: '/organization',
          children: [],
        },
        {
          name: '大会报告人',
          path: '/reporter',
          children: [],
        },
        {
          name: '会议日程',
          path: '/schedule',
          children: [],
        },
        {
          name: '报名缴费',
          path: '/attend',
          children: [],
        },
        //  {
        //   name: '摘要墙报',
        //   path: '/abstract',
        //   children: [],
        // },
        {
          name: '酒店预订',
          path: '/hotelReserve',
          children: [],
        },
        {
          name: '商务合作',
          path: '/exhibition',
          children: [],
        },
        {
          name: '下载中心',
          path: '/download',
          children: [],
        },
        // {
        //   name: '城市景点',
        //   path: '/scenic',
        //   children: [],
        // },
      ],
    };
  },
  computed: {
    selectedItem: {
      get() {
        return this.navList.findIndex((item) => item.path === this.$route.path);
      },
      set(val) {
        console.log(val);
      },
    },
  },
  mounted() {},
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    onJump() {
      // window.open('https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/abstract/202109/会议延期通知.pdf')
    },
    navClick(index, name) {
      //
      //   name === '/attend' && this.$store.state.user.name
      //     ? this.$router.push('/user?num=1')
      //     : this.$router.push(name);
      //
      this.$router.push(name);
    },
  },
};
</script>

<style lang="less">
.v-main {
  min-height: 100vh;
}
// pc端的图片
.banner1 {
  width: 100%;
  height: 3.5rem;
  background: url('../../../assets/img/banner2023.jpg') center no-repeat;
  background-size: cover;
  margin-bottom: 25px;
}
// 移动端
.banner2 {
  width: 100%;
  height: 250px;
  background: url('../../../assets/img/header_phone_2023.jpg') center no-repeat;
  background-size: cover;
  margin-bottom: 25px;
}
.header-top {
  height: 50px;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  //background: #474747;
  background-image: linear-gradient(to right, #014198, #16a086);
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  .left {
    display: flex;
    align-content: center;

    margin-left: 50px;

    img {
      width: 20px;
      height: 20px;
      line-height: 50px;
      vertical-align: middle;
      margin: 0 5px;
      margin-bottom: 3px;
    }
  }
  .right {
    margin-right: 50px;
    span {
      margin: 0 10px;
    }
    .login {
      color: #999;
      cursor: pointer;
      &:hover {
        color: #e33333 !important;
      }
    }
    .user {
      cursor: pointer;
    }
    .register {
      cursor: pointer;
      color: #e33333;
    }
  }
}
.content {
  background: #f5f5f5;
  transition: all ease 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    height: 80px;
    margin-right: 50px;
    margin-left: 100px;
    img {
      height: 80px;
    }
  }
  .header {
    display: flex;
    height: 110px;
    padding: 0 20px;
    li {
      color: #6b6b6b;
      font-size: 12px;
      line-height: 110px;
      padding: 0 15px;
      font-size: 15px;
      font-weight: bold;
      list-style: none;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      &.active {
        //color: #ff285a;
        color: #014198;

        .underline {
          opacity: 1;
          width: 100%;
          left: 0;
        }
      }
      .nav {
        margin: 0 15px;
        position: relative;
        height: 80px;
      }
      &:hover {
        //color: #ff285a;
        color: #014198;
        text-decoration: none;
        .underline {
          opacity: 1;
          width: 100%;
          left: 0;
        }
      }
      i {
        display: block;
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: 0;
        height: 2px;
        opacity: 0;
        transition: all 0.6s ease;
        //background-color: #ff285a;
        background-color: #014198;
      }
    }
  }
}
.mobile-item {
  .btns {
    position: absolute;
    right: 20px;
    top: 0;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    .login {
      color: #999;
      cursor: pointer;
      margin-right: 20px;
      &:hover {
        color: #e33333 !important;
      }
    }
    .user {
      color: #337fe5;
    }
    .register {
      cursor: pointer;
      color: #e33333;
    }
  }
}
.drawer {
  .v-navigation-drawer__content {
    background: #000000;
  }

  .mobile-header {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
    li {
      text-align: center;

      background: #000000;
      border-bottom: 1px solid #232323;
      color: #b1b1b1;
      height: 50px;
      line-height: 50px;
      &.active {
        background: #df5e66;
        border-top: 1px solid #232323;
        color: #fff;
      }
    }
  }
}
</style>
