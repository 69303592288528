import {
  login
} from '@/api/login'

import md5 from 'js-md5';
const user = {
  state: {
    token: '',
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    sign: '',
    uid: '',
    orderId:''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_SIGN: (state, sign) => {
      state.sign = sign
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_UID: (state, uid) => {
      state.uid = uid
    },
    SET_ORDER_ID: (state, id) => {
      state.orderId = id
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({
      commit
    }, userInfo) {
      const email = userInfo.email.trim()
      const passwd = userInfo.passwd
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(email, passwd, code, uuid).then(res => {
          let sign = ''
          sign = md5(res.data.uid + res.data.token + 'A0qeCCmYYh0VsxBT')
          commit('SET_TOKEN', res.data.token)
          commit('SET_SIGN', sign)
          commit('SET_UID', res.data.uid)
          commit('SET_NAME', res.data.email)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    



    // 登出
    FedLogOut({
      commit
    }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        commit('SET_UID', '')
        commit('SET_SIGN', '')
        commit('SET_ORDER_ID', '')
        resolve()
      })
    },
    order({commit},id){
      return new Promise(resolve => {
        commit('SET_ORDER_ID', id)
      
        
        resolve()
      })
    }
  }
}

export default user