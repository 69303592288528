 // 基准大小   1rem为16px
 //  const baseSize = 15;
 // 设置 rem 函数
 import store from '@/store'

 function setRem() {
   var winW = document.documentElement.clientWidth;
   let size = 0;
   if (winW <= 1300 && winW > 800) {
     store.commit('REVERSE_DRAWER', false)
     size = Math.round(winW / 13);
   } else if (winW <= 850) {

     store.commit('REVERSE_DRAWER', true)
     size = Math.round(winW / 3.75);
     if (size > 100) {
       size = 100
     }
     if (size < 50) {

       size = 50;
     }
   } else {
     size = 100;
     store.commit('REVERSE_DRAWER', false)
   }
   // 当前页面宽度相对于多宽的缩放比例，可根据自己需要修改。
   // const scale = document.documentElement.clientWidth / 1920;
   // // 设置页面根节点字体大小
   // if (baseSize * Math.min(scale, 2) < 10) {
   //   document.documentElement.style.fontSize = 10 + 'px';
   // } else {
   //   document.documentElement.style.fontSize =
   //     baseSize * Math.min(scale, 2) + 'px';
   // }
   document.documentElement.style.fontSize = size + 'px';
 }
 // 初始化
 setRem();
 // 改变窗口大小时重新设置 rem
 window.onresize = function () {
   setRem();
 };