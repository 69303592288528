import store from '@/store'




export function getName() {
    return store.state.user.name
}

export function setName(name) {
    return store.commit('SET_NAME', name)

}

export function removeName() {
    return store.commit('SET_NAME', '')

}

export function getToken() {
   
    return store.state.user.token
}

export function setToken(token) {
    return store.commit('SET_TOKEN', token)

}

export function removeToken() {
    return store.commit('SET_TOKEN', '')

}

export function getUid() {
    return store.state.user.uid
}

export function setUid(uid) {
    return store.commit('SET_UID', uid)

}

export function removeUid() {
    return store.commit('SET_UID', '')
}

export function getSign() {
    return store.state.user.sign
}

export function setSign(sign) {
    return store.commit('SET_SIGN', sign)
}

export function removeSign() {
    return store.commit('SET_SIGN', '')
}
export function setOrderId(orderId) {
    return store.commit('SET_ORDER_ID', orderId)
}

export function removeOrderId() {
    return store.commit('SET_ORDER_ID', '')
}