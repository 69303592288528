<template>
  <div class="share" v-if="flag&&!$store.state.navigation">
      <div class="share-title">
          2023分子植物育种大会
      </div>
      <div class="desc"></div>
      <div class="code"></div>
      <div class="bottom">
          扫描二维码分享到社交网络
      </div>
      <div class="close" @click='close'>

      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            flag:true
        }
    },
    methods:{
        close(){
            this.flag=false
        }
    }
};
</script>

<style lang="less" scoped>

.share{
    width: 200px;
    height: 311px;
    background: url('../../../assets/img/share-bg.png') no-repeat;
    position: fixed;
    right: 0;
    top:60%;
        text-align: center;
    .share-title{
        font-size: 18px!important;
        color: #333333;
        font-weight: bold;
        line-height: 36px;
        margin-top: 30px;

    }
    .desc{
        font-size: 12px;
        font-weight: 400;
        color:#666666;
        line-height: 36px;
    }
    .code{
        width: 166px;
        height: 166px;
        background: url('../../../assets/img/share-code.png') no-repeat;
        background-size: 100% 100%;
        margin: 10px auto;

    }
    .bottom{
        font-size: 14px;
        color: #333333;

    }
    .close{
        width: 13px;
        height: 13px;
        position: absolute;
        right: 10px;
        top: 10px;
        background: url('../../../assets/img/close.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
    }
}
</style>
