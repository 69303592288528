import request from '@/utils/request'

// 登录方法
export function login(email, passwd, code, uuid) {
    const data = {
        email,
        passwd,
        code,
        uuid
    }
    return request({
        url: '/user/login',
        method: 'post',
        data: data
    })
}




// // 获取验证码
// export function getCodeImg() {
//     return request({
//         url: '/captchaImage',
//         method: 'get'
//     })
// }