import axios from 'axios'
import { getToken, getUid, getSign } from '@/utils/auth'
import message from '@/utils/tips.js'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['uid'] = getUid()
      config.headers['sign'] = getSign()
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              let params = propName + '[' + key + ']'
              var subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = res.data.message
    if (code === 404) {
      message.tips('找不到')
      return Promise.reject(new Error(msg))
    } else if (code === 500) {
      message.tips(msg)
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      message.tips(msg)
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  (error) => {
    message.tips('系统异常')
    return Promise.reject(error)
  }
)

export default service
