export default {
  
    //弹窗提示自定义弹框
    tips: function (message,times) {
        /*设置信息框停留的默认时间*/
        var time = times;
        if (time == undefined || time == '') {
            time = 3000;
        }
        var el = document.createElement("div");
        el.setAttribute("class", "web-toast");
        el.style.zIndex = 9999999999999999
        el.innerHTML = message;
        document.body.appendChild(el);
        el.classList.add("fadeIn");
        setTimeout(function () {
            el.classList.remove("fadeIn");
            el.classList.add("fadeOut");
            /*监听动画结束，移除提示信息元素*/
            el.addEventListener("animationend", function () {
                document.body.removeChild(el);
            });
            el.addEventListener("webkitAnimationEnd", function () {
                document.body.removeChild(el);
            });

        }, time);
    }
};

