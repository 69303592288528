<template>
  <v-footer class="footer" absolute padless>
    <v-col class="text-center" cols="12">
      <strong>
        © 2023 MOLBREEDING. All Rights Reserved <a href="https://beian.miit.gov.cn">冀ICP备17024928号-1</a></strong
      >
    </v-col>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="less" scoped></style>
