<template>
  <v-main>
    <nav-header />
    <v-container>
      <router-view />
    </v-container>
    <share />
    <nav-footer />
  </v-main>
</template>

<script>
import NavHeader from './components/Header.vue';
import NavFooter from './components/Footer.vue';
import share from './components/share.vue';
export default {
  name: 'Layout',
  components: { NavHeader, NavFooter,share },
};
</script>

<style>
.container {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}
@media (min-width: 1264px) {
  .container {
    max-width: 1300px;
  }
}
</style>
